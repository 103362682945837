import {Button, Card, Col, Image, Row, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {getReport} from "../../api/repositories/TechnologyRepository";
import {StoreContext} from "../../index";
import {BASE_URL} from "../../api/BaseApi";
import styled from "styled-components";
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {P} from "../../components/P";
import {toast} from "react-toastify";

const Title = styled.p`
    color: red;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0rem;
`

export const ResultPage = () => {
    const storeContext = useContext(StoreContext)
    const history = useHistory();

    let { uuid } = useParams();
    const [report, setReport] = useState(undefined)
    const [leftCol, setLeftCol] = useState([])
    const [rightCol, setRightCol] = useState([])


    useEffect(() => {
        storeContext.UIStore.setLoading(true)
        const d = new Date();
        const getReportClosure = (startTime) => {

            const d1 = new Date()
            const currTime = d1.getTime()
            if ((currTime - startTime)/1000 >= 30) {
                storeContext.UIStore.setLoading(false)
                history.goBack()
                toast.error("Timeout error. Please try again.")
                return
            }

            getReport(uuid).then(resp => {
                const data = resp.data
                if (data.status === "finished") {
                    setReport(data)
                    renderReport(data.output)
                    storeContext.UIStore.setLoading(false)
                } else if (data.status === "created" || data.status === "processing") {
                    setTimeout(() => getReportClosure(currTime), 500)
                } else {
                    storeContext.UIStore.setLoading(false)
                    history.goBack()
                    toast.error("There was an error generating your report. Please try again.")
                }


            }).catch(err => {
                storeContext.UIStore.setLoading(false)
                history.goBack()
                console.log(err)
                toast.error("Network error. Check your internet connection and try again.")
            })
        }
        getReportClosure(d.getTime())

    }, [])

    const renderReport = (report) => {
        const newLeftCol = []
        const newRightCol = []
        for (let i = 0; i < report.length; i ++) {
            const section = report[i]
            const summaryJSX = renderSummary(section.summary, BASE_URL + section.icon_url)
            const contentJSX = []
            for(let j = 0; j < section.contents.length; j ++) {
                const content = section.contents[j]
                contentJSX.push(renderTitle(content.title))
                if (content.type === "table") {
                    contentJSX.push(renderTable(content.data))
                } else if (content.type === "graph") {
                    contentJSX.push(renderChart(content.data))
                } else if (content.type === "image") {
                    contentJSX.push(renderImage(BASE_URL + content.data))
                }
            }

            // Switch between left and right col on page
            if (i % 2 === 0) {
                newLeftCol.push(summaryJSX)
                newLeftCol.push(contentJSX)
            } else {
                newRightCol.push(summaryJSX)
                newRightCol.push(contentJSX)
            }

        }
        setLeftCol(newLeftCol)
        setRightCol(newRightCol)
    }

    const renderSummary = (summaryText, iconUrl) => {
        return <Card style={{marginTop: '2rem', backgroundColor: '#E2E2E2'}}>
            <Card.Body>
                <Image src={iconUrl} style={{width: '1.3rem', verticalAlign: 'text-top'}}/>
                {' '}
                {summaryText}
            </Card.Body>
        </Card>
    }

    const renderTitle = (titleText) => {
        return <Title>{titleText}</Title>
    }

    const renderTable = (tableData) => {
        const table = []
        tableData.forEach(row => {
            const rowJSX = []
            row.forEach(col => {
                rowJSX.push(<td>{col}</td>)
            })
            table.push(<tr>{rowJSX}</tr>)
        })
        return <Table striped responsive><tbody>{table}</tbody></Table>
    }

    const renderChart = (chartData) => {
        /*
        const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  }]
         */
        const mergedChartData = []
        const lines = []
        for (let i = 0; i < chartData.lines.length; i ++){
            const lineInfo = chartData.lines[i]
            const line = lineInfo.data_points
            const lineName = lineInfo.line_name
            const lineColor = lineInfo.line_color
            for (let j = 0; j < line.length; j ++) {
                const dataPoint = line[j]
                const mergedDataPoints = mergedChartData.filter(function (el) {
                    return el.year === dataPoint.x
                });
                if (mergedDataPoints.length > 0) {
                    const mergedDataPoint = mergedDataPoints[0]
                    mergedDataPoint[i] = dataPoint.y
                } else {
                    const toAdd = {
                        year: dataPoint.x
                    }
                    toAdd[i] = dataPoint.y
                    mergedChartData.push(toAdd)
                }
            }
            //
            lines.push(
                <Line type="monotone"
                      dataKey={i}
                      stroke={lineColor}
                      name={lineName}
                      formatter={(value, name, props) => ( new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)) }
                />
            )
        }

        return <ResponsiveContainer width='100%'
                                    height={300}>
            <LineChart
                data={mergedChartData}
                width='100%'
                height={500}
                margin={{ left: 70 }}
            >
                <XAxis dataKey="year" label={{ value: chartData.x_axis_label, dy: 10}} />
                <YAxis label={{ value: chartData.y_axis_label, position: "insideLeft", angle: -90,  dx: -40, dy: 60}} />
                <Tooltip labelFormatter={(label) => 'Year ' + label} />
                {lines}
                <Legend verticalAlign="bottom" height={36}/>
            </LineChart>
        </ResponsiveContainer>
    }

    const renderImage = (imageUrl) => {
        return <Image src={imageUrl} fluid />
    }

    const printPage = () => {
        window.print();
    }

    const contactClicked = () => {
        if (report !== undefined) {
            history.push('/report/'+report.uuid+'/contact')
        }
    }

    const backClicked = () => {
        history.goBack()
    }

    return(
        <>
            <Row>
                <Col className="mt-4" xl={{span: 8, offset: 2}}>
                    <P bold={true}>Results</P>
                    <div className="float-right text-right">
                        <Button variant="info" onClick={backClicked} className="mr-1">Back</Button>
                        <Button variant="danger" onClick={contactClicked} className="mr-1">Click here to learn More</Button>
                        <Button variant="outline-danger" onClick={printPage}>Print</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col id='leftMainCol' xl={{span: 4, offset: 2}} lg={{span: 5, offset: 1}}>
                    {leftCol}
                </Col>
                <Col id='rightMainCol' xl={4} lg={5}>
                    {rightCol}
                </Col>
            </Row>
        </>
    )
}