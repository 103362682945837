import {baseApi} from "../BaseApi";

export const getTechnologies = () => {
    return baseApi.get('/technologies')
}

export const createReport = (technologyId, dailyOutboundOrders, dailyOutboundLines, hoursWorkedPerDay, acceptedTos) => {
    return baseApi.post('/technologies/'+technologyId+'/reports/', {
        "daily_outbound_orders": parseFloat(dailyOutboundOrders),
        "daily_outbound_lines": parseFloat(dailyOutboundLines),
        "hours_worked_per_day": hoursWorkedPerDay,
        "accepted_tos": acceptedTos
    })
}

export const getReport = (reportUUID) => {
    return baseApi.get('/reports?uuid='+reportUUID)
}

export const notify = (technologyId, name, email, phoneNumber) => {
    return baseApi.post('/technologies/'+technologyId+'/notify/', {
        "name": name,
        "email": email,
        "phone_number": phoneNumber
    })
}

export const contact = (reportUUID, name, email, phoneNumber, company) => {
    return baseApi.post('/reports/'+reportUUID+'/contact/', {
        "name": name,
        "email": email,
        "phone_number": phoneNumber,
        "company": company
    })
}