import {Navbar} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";

const LogoImg = styled.img`
    width: 7rem;
`

const StyledNavbar = styled(Navbar)`
  -webkit-box-shadow: 0px 0px 13px -6px #000000;
  box-shadow: 0px 0px 13px -6px #000000;
`

export function Header() {
    const history = useHistory();
    const location = useLocation();

    const logoClicked = () => {
        history.push('/')
    }

    const headerDisabledPaths = [
        '/login'
    ]

    return(
        <>
            {!headerDisabledPaths.includes(location.pathname) && <StyledNavbar bg="light" expand="lg">
                <Navbar.Brand as='a' onClick={logoClicked}><LogoImg src="/logo.png" width="80px"/></Navbar.Brand>
            </StyledNavbar>}
        </>
    )
}