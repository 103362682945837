import {Col, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {ListTechnologies} from "../../components/ListTechnologies";
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../index";
import {useHistory} from "react-router-dom";
import {PrimaryButton} from "../../components/PrimaryButton";
import styled from "styled-components";
import {toast} from "react-toastify";

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 4rem;
`
export const TechnologySelectionPage = observer(() => {

    const storeContext = useContext(StoreContext)
    const history = useHistory();

    useEffect(() => {
        storeContext.ReportStore.loadAvailableTechnologies()
    }, [])

    const handleTechnologySelection = (technology) => {
        storeContext.ReportStore.setSelectedTechnology(technology)
    }

    const ctnBtnClicked = () => {
        if (storeContext.ReportStore.selectedTechnology === undefined) {
            toast.error("Please select a technology.")
            return
        }

        if (storeContext.ReportStore.selectedTechnology.enabled) {
            history.push('/warehouse-info')
        } else {
            history.push('/coming-soon')
        }
    }

    return (
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col>
                <Row className="text-center justify-content-center">
                    <ListTechnologies technologies={storeContext.ReportStore.availableTechnologies} handleTechnologySelection={handleTechnologySelection} selectedTechnology={storeContext.ReportStore.selectedTechnology}/>
                </Row>
                <Row className="text-center">
                    <Col>
                        <StyledPrimaryButton onClick={ctnBtnClicked}>Continue</StyledPrimaryButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
})