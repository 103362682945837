import {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {StoreContext} from "../../index";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {createReport} from "../../api/repositories/TechnologyRepository";
import {useHistory} from "react-router-dom";
import {P} from "../../components/P";
import {FormControl} from "../../components/FormControl";
import styled from "styled-components";
import {PrimaryButton} from "../../components/PrimaryButton";

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 4rem;
`

const ErrorText = styled.p`
  font-size: 80%;
  color: #dc3545;
`

export const WarehouseDataInputPage = observer(() => {
    const storeContext = useContext(StoreContext)
    const history = useHistory();
    const [dailyOutboundOrders, setDailyOutboundOrders] = useState("100")
    const [dailyOutboundLines, setDailyOutboundLines] = useState("100")
    const [dailyOutboundLinesError, setDailyOutboundLinesError] = useState(undefined)
    const [agreedToTos, setAgreedToTos] = useState(false)

    const [hoursWorkedPerDay, setHoursWorkedPerDay] = useState(undefined)
    const [hoursWorkedPerDayError, setHoursWorkedPerDayError] = useState(undefined)
    const [tosError, setTosError] = useState(undefined)

    const dailyOutboundOrdersChanged =(e) => {
        setDailyOutboundOrders(e.target.value)
    }

    const dailyOutboundLinesChanged =(e) => {
        setDailyOutboundLinesError(undefined)
        validateDailyOutboundLines(e.target.value)
        setDailyOutboundLines(e.target.value)
    }

    const validateDailyOutboundLines = (dailyOutboundLines) => {
        setDailyOutboundLinesError(undefined)
        if (parseFloat(dailyOutboundLines) < parseFloat(dailyOutboundOrders)) {
            setDailyOutboundLinesError("Daily outbound lines can't be lower than daily outbound orders.")
            return false
        }
        return true
    }

    const validateHoursWorked = () => {
        setHoursWorkedPerDayError(undefined)
        if(hoursWorkedPerDay === undefined) {
            setHoursWorkedPerDayError("Please enter a value.")
            return false
        }
        const hoursWorkedPerDayFloat = parseFloat(hoursWorkedPerDay)
        if(hoursWorkedPerDayFloat <= 0) {
            setHoursWorkedPerDayError("Hours worked per day must be greater than 0.")
            return false
        }
        if(hoursWorkedPerDayFloat > 24) {
            setHoursWorkedPerDayError("Hours worked per day cannot be greater than 24.")
            return false
        }
        return true
    }

    const hoursWorkedPerDayChanged =(e) => {
        const val = e.target.value
        if (isNaN(val)) {
            return
        }
        if (val.includes(".")) {
            return
        }
        setHoursWorkedPerDay(parseInt(val))
    }

    const validateAgreedTos = () => {
        setTosError(undefined)
        if (!agreedToTos) {
            setTosError("You must agree to our terms of service to use this application.")
            return false
        }
        return true
    }

    const continueClicked = (event) => {
        if (event) {
            event.preventDefault()
        }
        if (!validateDailyOutboundLines(dailyOutboundLines) || !validateHoursWorked() || !validateAgreedTos()) {
            return
        }
        storeContext.UIStore.setLoading(true)
        createReport(storeContext.ReportStore.selectedTechnology.id, dailyOutboundOrders, dailyOutboundLines, hoursWorkedPerDay, agreedToTos)
            .then(resp => {
                const report_uuid = resp.data.report_uuid
                history.push('/report/' + report_uuid)
            }).catch(err => {
                // TODO: Handle errors
            }).finally(() => {
                storeContext.UIStore.setLoading(false)
        })
    }

    return(
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8}>
                <Row>
                    <Col>
                        <P bold={true}>Tell us about your current fulfillment operation</P>
                    </Col>
                </Row>
                <Form onSubmit={continueClicked}>
                <Row>
                    <Col>

                            <Form.Group controlId="dailyOutboundOrders">
                                <Form.Label>Daily outbound orders</Form.Label>
                                <FormControl as="select" onChange={dailyOutboundOrdersChanged}>
                                    <option>100</option>
                                    <option>300</option>
                                    <option>500</option>
                                    <option>750</option>
                                    <option>1000</option>
                                    <option>1200</option>
                                    <option>1500</option>
                                    <option>2000</option>
                                    <option>2500</option>
                                    <option>3000</option>
                                    <option>3500</option>
                                    <option>4000</option>
                                    <option>4500</option>
                                    <option>5000</option>
                                </FormControl>
                            </Form.Group>
                            <Form.Group controlId="dailyOutboundLines">
                                <Form.Label>Daily outbound lines</Form.Label>
                                <FormControl as="select" onChange={dailyOutboundLinesChanged}>
                                    <option>100</option>
                                    <option>300</option>
                                    <option>500</option>
                                    <option>750</option>
                                    <option>1000</option>
                                    <option>1200</option>
                                    <option>1500</option>
                                    <option>2000</option>
                                    <option>2500</option>
                                    <option>3000</option>
                                    <option>3500</option>
                                    <option>4000</option>
                                    <option>4500</option>
                                    <option>5000</option>
                                    <option>5500</option>
                                    <option>6000</option>
                                    <option>6500</option>
                                    <option>7000</option>
                                    <option>7500</option>
                                    <option>8000</option>
                                    <option>8500</option>
                                    <option>9000</option>
                                    <option>9500</option>
                                    <option>10000</option>
                                </FormControl>
                                {dailyOutboundLinesError !== undefined && <ErrorText>{dailyOutboundLinesError}</ErrorText>}
                            </Form.Group>
                            <Form.Group controlId="hoursWorkedPerDay">
                                <Form.Label>Hours worked per day</Form.Label>
                                <FormControl type="number" placeholder="Enter a number" min="0" onChange={hoursWorkedPerDayChanged}
                                value={hoursWorkedPerDay} isInvalid={hoursWorkedPerDayError !== undefined} />
                                <Form.Control.Feedback type="invalid">
                                    {hoursWorkedPerDayError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Check
                                type='checkbox'
                                label={<>I agree to the <a href="terms-and-conditions" target="_blank">Terms and Conditions</a></>}
                                onChange={(e) => {setAgreedToTos(e.target.checked)}}
                                checked={agreedToTos}
                                required
                                isInvalid={tosError !== undefined}
                            />
                            {tosError !== undefined && <ErrorText>{tosError}</ErrorText>}
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <StyledPrimaryButton
                            onClick={continueClicked} type="submit">
                                Continue
                        </StyledPrimaryButton>
                    </Col>
                </Row>
                </Form>
            </Col>
        </Row>
    )
})