import {Col, Row} from "react-bootstrap";
import {P} from "../../components/P";
import {useHistory, useParams} from "react-router-dom";


export const CompletedPage = (props) => {
    const history = useHistory()

    let { message } = useParams()

    const goToLoginPage = () => {
        history.push('/login')
    }

    return (
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8} className="text-center">
                <P bold={true}>{message}</P>
                <a href='' onClick={goToLoginPage}>Click here to go to the login page</a>
                <br />
                <svg className="mt-4" fill="#5cb85c" height="100" viewBox="0 0 24 24" width="100" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                    <path color="#5cb85c" d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"/>
                </svg>
            </Col>
        </Row>
    )
}