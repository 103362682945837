import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {StoreContext} from "../index";
import styled from 'styled-components'
import {SyncLoader} from "react-spinners";
import { css } from "@emotion/core";

const Overlay = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
`

const override = css`
    position: fixed;
    top: 50%;
    left: 50%;
`

export const LoadingOverlay = observer(() => {

    const storeContext = useContext(StoreContext)

    return(
        <>
            {
                storeContext.UIStore.loading && <Overlay>
                    <SyncLoader css={override} loading={true} size={20} color='#fff'/>
                </Overlay>
            }
        </>
    )
})