import styled from "styled-components";
import {Form} from "react-bootstrap";

const StyledFormControl = styled(Form.Control)`
  &:focus {
    border-color: ${ (props) => props.theme.primaryColor };
    box-shadow: 0 0 0 0.2rem rgb(220 72 65 / 25%);
  }
  &:focus-visible {
    outline: ${ (props) => props.theme.primaryColor };
  }
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const FormControl = (props) => {
    return(
        <StyledFormControl {...props} >{props.children}</StyledFormControl>
    )
}