import {UIStore} from "./UIStore";
import {ReportStore} from "./ReportStore";

export class RootStore {
    UIStore = undefined
    ReportStore = undefined

    constructor() {
        this.UIStore = new UIStore(this)
        this.ReportStore = new ReportStore(this)
    }
}