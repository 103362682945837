import {action, makeObservable, observable} from "mobx";

export class UIStore {
    rootStore = undefined
    loading = false

    constructor(rootStore) {
       this.rootStore = rootStore
        makeObservable(this, {
            loading: observable,
            setLoading: action
        })
    }

    setLoading(enabled) {
        this.loading = enabled
    }
}