import {Col, Form, Row} from "react-bootstrap";
import {P} from "../../components/P";
import {FormControl} from "../../components/FormControl";
import {PrimaryButton} from "../../components/PrimaryButton";
import {useState} from "react";
import {isEmailValid} from "../../utils/FormUtils";
import {resetPassword} from "../../api/repositories/AuthRepository";
import {useHistory} from "react-router-dom";

export const ForgotPassword = () => {
    const history = useHistory();

    const [email, setEmail] = useState("")
    const [emailValid, setEmailValid] = useState(undefined)

    const emailChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (char === ' ') {
                return
            }
        }
        setEmail(val)
    }

    const validateEmail = () => {
        if(isEmailValid(email)) {
            setEmailValid(undefined)
            return true
        } else {
            setEmailValid("Please enter a valid email address.")
            return false
        }
    }

    const resetPasswordClicked = () => {
        if (!validateEmail()) {
            return
        }

        resetPassword(email).finally(() => {
            history.push("/completed/If an account exists with that email, we've sent it a password reset link.")
        })
    }

    return(
         <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
             <Col xl={4} lg={6} md={8} sm={8}>
                <P bold={true}>Forgot password</P>
                <P>We'll send a password reset link to the email provided below.</P>
                 <Form>
                     <Form.Group controlId="formBasicEmail">
                         <Form.Label>Email address</Form.Label>
                         <FormControl type="email" onChange={emailChanged} value={email} isInvalid={emailValid !== undefined}/>
                         <Form.Control.Feedback type="invalid">
                             {emailValid}
                         </Form.Control.Feedback>
                     </Form.Group>
                     <PrimaryButton onClick={resetPasswordClicked}>
                         Send Password Reset Link
                     </PrimaryButton>
                 </Form>
             </Col>
         </Row>
    )
}