import {action, makeObservable, observable} from "mobx"
import {getTechnologies} from "../api/repositories/TechnologyRepository";

export class ReportStore {
    rootStore = undefined
    availableTechnologies = []
    selectedTechnology = undefined

    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            availableTechnologies: observable,
            selectedTechnology: observable,
            setSelectedTechnology: action,
        })
    }

    loadAvailableTechnologies() {
        this.rootStore.UIStore.setLoading(true)
        getTechnologies().then((resp) => {
            this.availableTechnologies = resp.data.technologies
            console.log(resp.data.technologies)
        }).catch((err) => {
            //TODO: handle errors
        }).finally(() => {
            this.rootStore.UIStore.setLoading(false)
        })
    }

    setSelectedTechnology(technology) {
        this.selectedTechnology = technology
    }
}