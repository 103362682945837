import {baseApi} from "../BaseApi";

export const register = (email, password, passwordVerification) => {
    return baseApi.post('/auth', {
        "email": email,
        "password": password,
        "password_confirmation": passwordVerification
    })
}

export const resetPassword = (email) => {
    return baseApi.post('/auth/password', {
        "email": email
    })
}

export const changePassword = (password, passwordVerification, uid, client, accessToken) => {
    return baseApi.put('/auth/password',{
        "password": password,
        "password_confirmation": passwordVerification,
    }, {
        headers: {
            "uid": uid,
            "client": client,
            "access-token": accessToken
        }
    })
}

export const login = (email, password) => {
    return baseApi.post('/auth/sign_in', {
        "email": email,
        "password": password
    })
}