import {
    Switch,
    Route, Redirect,
} from "react-router-dom";
import {TechnologySelectionPage} from "./pages/report/TechnologySelectionPage";
import {WarehouseDataInputPage} from "./pages/report/WarehouseDataInputPage";
import {PrivateRoute} from "./PrivateRoute";
import {ResultPage} from "./pages/report/ResultPage";
import {ComingSoonPage} from "./pages/report/ComingSoonPage";
import {Login} from "./pages/auth/Login";
import {Register} from "./pages/auth/Register";
import {ForgotPassword} from "./pages/auth/ForgotPassword";
import {ResetPassword} from "./pages/auth/ResetPassword";
import {CompletedPage} from "./pages/auth/CompletedPage";
import {ContactPage} from "./pages/report/ContactPage";
import {TermsAndConditions} from "./pages/misc/TermsAndConditions";

export const Routes = () => {
    return (
        <Switch>

            {/* Authentication */}
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/register">
                <Register />
            </Route>
            <Route exact path="/forgot-password">
                <ForgotPassword />
            </Route>
            <Route exact path="/reset-password">
                <ResetPassword />
            </Route>
            <Route exact path="/completed/:message">
                <CompletedPage />
            </Route>

            {/* Report generation */}
            <Route exact path="/">
                <TechnologySelectionPage/>
            </Route>
            <Route path="/report/:uuid/contact">
                <ContactPage/>
            </Route>
            <Route path="/report/:uuid">
                <ResultPage/>
            </Route>
            <PrivateRoute path="/warehouse-info">
                <WarehouseDataInputPage />
            </PrivateRoute>
            <PrivateRoute path="/coming-soon">
                <ComingSoonPage />
            </PrivateRoute>

            {/* Misc */}
            <Route path="/terms-and-conditions">
                <TermsAndConditions/>
            </Route>

            {/* Default */}
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}