import styled from "styled-components";
import {Button, Col, Form, Row} from "react-bootstrap";
import {PrimaryButton} from "../../components/PrimaryButton";
import {P} from "../../components/P";
import {FormControl} from "../../components/FormControl";
import {useHistory} from "react-router-dom";
import {SecondaryButton} from "../../components/SecondaryButton";
import {useState} from "react";
import {isEmailValid} from "../../utils/FormUtils";
import {login} from "../../api/repositories/AuthRepository";
import {toast} from "react-toastify";

const LogoImg = styled.img`
    width: 15rem;
`

const StyledCol = styled(Col)`
    background-color: white;
    border: 1px solid #CCCCCC;
    padding: 3rem 2rem 3rem 2rem;
    border-radius: 0.5rem;
`

export const Login = () => {
    const history = useHistory();

    const [email, setEmail] = useState("")
    const [emailValid, setEmailValid] = useState(undefined)

    const [password, setPassword] = useState("")
    const [passwordValid, setPasswordValid] = useState(undefined)

    const registerClicked = () => {
        history.push('/register')
    }

    const forgotPasswordClicked = () => {
        history.push('/forgot-password')
    }

    const emailChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (char === ' ') {
                return
            }
        }
        setEmail(val)
    }

    const passwordChanged = (e) => {
        const val = e.target.value
        setPassword(val)
    }

    const validateEmail = () => {
        if(isEmailValid(email)) {
            setEmailValid(undefined)
            return true
        } else {
            setEmailValid("Please enter a valid email address.")
            return false
        }
    }

    const validatePassword = () => {
        if (password.length <= 0) {
            setPasswordValid("Please enter a password")
            return false
        } else {
            setPasswordValid(undefined)
            return true
        }
    }

    const loginClicked = () => {
        if (!validateEmail() || !validatePassword()) {
            return
        }
        login(email, password).then((resp) => {
            toast.success("Logged in.")
            history.push('/')
        }).catch((err) => {
            if(err.response.data) {
                toast.error(err.response.data.errors[0])
            } else {
                toast.error("An error occurred. Please try again.")
            }
        })
    }

    return(
        <Row className="justify-content-center align-items-center" style={{height: "100vh"}}>
            <StyledCol xl={8} lg={10} md={12} sm={12}>
                <Row>
                    <Col className="text-center align-middle justify-content-center align-items-center" style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <LogoImg src="/logo.png"/>
                    </Col>
                    <Col>
                        <P bold={true}>
                            Please login to access the NanoFC Configurator
                        </P>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <FormControl type="email" onChange={emailChanged} value={email} isInvalid={emailValid !== undefined}/>
                                <Form.Control.Feedback type="invalid">
                                    {emailValid}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <FormControl type="password" onChange={passwordChanged} value={password} isInvalid={passwordValid !== undefined}/>
                                <Form.Control.Feedback type="invalid">
                                    {passwordValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <SecondaryButton onClick={forgotPasswordClicked}>
                                        Forgot Password
                                    </SecondaryButton>
                                </Col>
                                <Col className="text-center">
                                    <PrimaryButton onClick={loginClicked}>
                                        Login
                                    </PrimaryButton>
                                    <br/>
                                    <a onClick={registerClicked} className="mt-5" href="">New member?</a>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </StyledCol>
        </Row>
    )
}