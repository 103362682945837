import {observer} from "mobx-react-lite";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useContext, useState} from "react";
import {StoreContext} from "../../index";
import {notify} from "../../api/repositories/TechnologyRepository";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {FormControl} from "../../components/FormControl";
import {PrimaryButton} from "../../components/PrimaryButton";
import {P} from "../../components/P";
import styled from "styled-components";
import {containsWhiteSpace, isEmailValid} from "../../utils/FormUtils";

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 4rem;
`

export const ComingSoonPage = observer(() => {
    const storeContext = useContext(StoreContext)
    const history = useHistory();

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [nameValid, setNameValid] = useState(undefined)
    const [phoneNumberValid, setPhoneNumberValid] = useState(undefined)
    const [emailValid, setEmailValid] = useState(undefined)

    const notifyMeBtnClicked = () => {
        setNameValid(undefined)
        setPhoneNumberValid(undefined)
        setEmailValid(undefined)
        if (name.length <= 0) {
            setNameValid("Please enter your name")
            return
        }
        if (phoneNumber.length <= 0) {
            setPhoneNumberValid("Please enter your phone number")
            return
        }
        if (!isEmailValid(email)) {
            setEmailValid("Please enter a valid email")
            return
        }
        notify(storeContext.ReportStore.selectedTechnology.id, name, email, phoneNumber)
            .then((resp) => {
                toast.success("We'll notify you once its ready!")
                history.push('/')
            }).catch((err) => {
                //TODO: Api call
                toast.error("Sorry, there was an issue! Please try again.")
        })
    }

    const nameChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (isNumber(char) && char !== "'" && char !== '-' && char !== ' ') {
                return
            }
        }
        setName(val)
    }

    const numberChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (!isNumber(char) && char !== '+') {
                return
            }
        }
        setPhoneNumber(val)
    }

    const isNumber = (char) => {
        return char.toUpperCase() === char.toLowerCase()
    }

    const emailChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (char === ' ') {
                return
            }
        }
        setEmail(val)
    }

    return(
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8}>
                <Row>
                    <Col>
                        <P bold={true}>Coming Soon</P>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Hey there, {storeContext.ReportStore.selectedTechnology.name} is coming soon. Please put in your info so you can be the first to know once its ready!</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <FormControl type="text" placeholder="Enter your name" isInvalid={nameValid !== undefined} onChange={nameChanged} value={name} />
                                <Form.Control.Feedback type="invalid">
                                    {nameValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="phoneNumber">
                                <Form.Label>Phone number</Form.Label>
                                <FormControl type="text" placeholder="Enter your phone number" isInvalid={phoneNumberValid !== undefined} onChange={numberChanged} value={phoneNumber}/>
                                <Form.Control.Feedback type="invalid">
                                    {phoneNumberValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <FormControl type="email" placeholder="Enter your email" isInvalid={emailValid !== undefined} onChange={emailChanged} value={email}/>
                                <Form.Control.Feedback type="invalid">
                                    {emailValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <StyledPrimaryButton onClick={notifyMeBtnClicked} >Notify me</StyledPrimaryButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
})