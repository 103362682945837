import styled from "styled-components";

const StyledP = styled.p`
  ${({ bold }) => bold && `
    font-weight: 700;
  `}
`

export const P = (props) => {
    return(
        <StyledP bold={props.bold}>{props.children}</StyledP>
    )
}