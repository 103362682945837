import {Col, Form, Row} from "react-bootstrap";
import {FormControl} from "../../components/FormControl";
import {PrimaryButton} from "../../components/PrimaryButton";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {changePassword} from "../../api/repositories/AuthRepository";
import {toast} from "react-toastify";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ResetPassword = () => {
    const queryParams = useQuery()
    const history = useHistory()

    const [password, setPassword] = useState("")
    const [passwordValid, setPasswordValid] = useState(undefined)

    const [passwordVerification, setPasswordVerification] = useState("")
    const [passwordVerificationValid, setPasswordVerificationValid] = useState(undefined)

    const [sessionConfig, setSessionConfig] = useState({
        accessToken: undefined,
        clientId: undefined,
        resetPassword: undefined,
        token: undefined,
        uid: undefined
    })

    useEffect(() => {
        //access-token=1aoL9uMIuQGL5gXwLmoTeg&client=8EdaS-b1Sf8BuwINhX5nuw
        // client_id=8EdaS-b1Sf8BuwINhX5nuw&config=default&expiry=1621306899
        // reset_password=true
        // token=1aoL9uMIuQGL5gXwLmoTeg
        // uid=tedi%40budware.ca

        const accessToken = queryParams.get("access-token")
        const clientId = queryParams.get("client_id")
        const resetPassword = queryParams.get("reset_password")
        const token = queryParams.get("token")
        const uid = queryParams.get("uid") ? decodeURI(queryParams.get("uid")) : undefined

        if (!(accessToken && clientId && resetPassword && token && uid)) {
            history.push('/login')
        }
        setSessionConfig({
            accessToken,
            clientId,
            resetPassword,
            token,
            uid
        })
    }, [])

    const passwordChanged = (e) => {
        const val = e.target.value
        setPassword(val)
    }

    const passwordVerificationChanged = (e) => {
        const val = e.target.value
        setPasswordVerification(val)
    }

    const validatePassword = () => {
        if (password.length <= 0) {
            setPasswordValid("Please enter a password")
            return false
        } else {
            setPasswordValid(undefined)
            return true
        }
    }

    const validatePasswordConfirmation = () => {

        if (password !== passwordVerification) {
            setPasswordVerificationValid("Password's do not match")
            return false
        } else {
            setPasswordVerificationValid(undefined)
            return true
        }
    }

    const changePasswordClicked = () => {
        if (!validatePassword()
            || !validatePasswordConfirmation()) {
            return
        }
        changePassword(
            password,
            passwordVerification,
            sessionConfig.uid,
            sessionConfig.clientId,
            sessionConfig.accessToken
        ).then((resp) => {
            history.push('/completed/Password successfully changed. Please login.')
        }).catch((err) => {
            toast.error("Your password reset has expired. Please try again.")
            history.push('/forgot-reset')
        })
    }

    return (
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8}>
                <Form>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <FormControl type="password" onChange={passwordChanged} value={password} isInvalid={passwordValid !== undefined}/>
                        <Form.Control.Feedback type="invalid">
                            {passwordValid}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPasswordVerification">
                        <Form.Label>Re-enter password</Form.Label>
                        <FormControl type="password" onChange={passwordVerificationChanged} value={passwordVerification} isInvalid={passwordVerificationValid !== undefined}/>
                        <Form.Control.Feedback type="invalid">
                            {passwordVerificationValid}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <PrimaryButton onClick={changePasswordClicked}>
                        Change Password
                    </PrimaryButton>
                </Form>
            </Col>
        </Row>
    )
}