
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes} from "./Routes";
import {Header} from "./components/Header";
import {Container} from "react-bootstrap";
import {BrowserRouter as Router} from "react-router-dom";
import {LoadingOverlay} from "./components/LoadingOverlay";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import "@fontsource/montserrat"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/500.css"

const StyledContainer = styled(Container)`
    font-family: Montserrat, sans-serif;
    background-color: #FAFAFA;
`

export const App = () => {
  return (
      <>
          <Router>
            <Header/>
            <StyledContainer fluid>
                <Routes/>
            </StyledContainer>
          </Router>
          <LoadingOverlay/>
          <ToastContainer />
      </>
  );
}

export default App;
