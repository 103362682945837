import {useHistory, useParams} from "react-router-dom";
import {useState} from "react";
import {isEmailValid} from "../../utils/FormUtils";
import {contact, notify} from "../../api/repositories/TechnologyRepository";
import {toast} from "react-toastify";
import {Col, Form, Row} from "react-bootstrap";
import {P} from "../../components/P";
import {FormControl} from "../../components/FormControl";
import styled from "styled-components";
import {PrimaryButton} from "../../components/PrimaryButton";

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 4rem;
`

export const ContactPage = () => {
    let { uuid } = useParams();
    const history = useHistory();

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [nameValid, setNameValid] = useState(undefined)
    const [phoneNumberValid, setPhoneNumberValid] = useState(undefined)
    const [emailValid, setEmailValid] = useState(undefined)
    const [companyValid, setCompanyValid] = useState(undefined)


    const contactBtnClicked = () => {
        setNameValid(undefined)
        setPhoneNumberValid(undefined)
        setEmailValid(undefined)
        setCompanyValid(undefined)
        if (name.length <= 0) {
            setNameValid("Please enter your name")
            return
        }
        if (phoneNumber.length <= 0) {
            setPhoneNumberValid("Please enter your phone number")
            return
        }
        if (!isEmailValid(email)) {
            setEmailValid("Please enter a valid email")
            return
        }
        if(company.length <= 0) {
            setCompanyValid("Please enter your company name")
            return
        }
        contact(uuid, name, email, phoneNumber, company)
            .then((resp) => {
                toast.success("Thank you! A representative will reach out you!")
                history.goBack()
            }).catch((err) => {
                console.log(err)
                toast.error("Sorry, there was an issue! Please try again.")
            })
    }

    const nameChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (isNumber(char) && char !== "'" && char !== '-' && char !== ' ') {
                return
            }
        }
        setName(val)
    }

    const numberChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (!isNumber(char) && char !== '+') {
                return
            }
        }
        setPhoneNumber(val)
    }

    const isNumber = (char) => {
        return char.toUpperCase() === char.toLowerCase()
    }

    const emailChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (char === ' ') {
                return
            }
        }
        setEmail(val)
    }

    const companyChanged = (e) => {
        setCompany(e.target.value)
    }

    return(
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8}>
                <Row>
                    <Col>
                        <P bold={true}>Learn More</P>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Interested in automating your fulfillment operation? Fill out the form below and a representative will reach out to you.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <FormControl type="text" placeholder="Enter your name" isInvalid={nameValid !== undefined} onChange={nameChanged} value={name} />
                                <Form.Control.Feedback type="invalid">
                                    {nameValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <FormControl type="email" placeholder="Enter your email" isInvalid={emailValid !== undefined} onChange={emailChanged} value={email}/>
                                <Form.Control.Feedback type="invalid">
                                    {emailValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="phoneNumber">
                                <Form.Label>Phone number</Form.Label>
                                <FormControl type="text" placeholder="Enter your phone number" isInvalid={phoneNumberValid !== undefined} onChange={numberChanged} value={phoneNumber}/>
                                <Form.Control.Feedback type="invalid">
                                    {phoneNumberValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="company">
                                <Form.Label>Company</Form.Label>
                                <FormControl type="text" placeholder="Enter your company name" isInvalid={companyValid !== undefined} onChange={companyChanged} value={company} />
                                <Form.Control.Feedback type="invalid">
                                    {companyValid}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <StyledPrimaryButton onClick={contactBtnClicked}>Submit</StyledPrimaryButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}