import {observer} from "mobx-react-lite";
import {BASE_URL} from "../api/BaseApi";
import styled from "styled-components";
import {P} from "./P";

const StyledA = styled.a`
    cursor: pointer;
    display: inline-block;
      &:hover {
        text-decoration: none;
      }
`

const TechnologyImage = styled.img`
    width: 15rem;
`

const Section = styled.div`
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 0.25rem;
  border-color: ${props => props.active ? props.theme.primaryColor : "none"};
  overflow: hidden;
  &:hover {
    border-color: ${props => props.theme.primaryColorHover }
  }
`

const Label = styled.p`
    margin-top: 1rem;
    text-align: center;
    color: black;
    font-weight: ${props => props.active ? 700 : 300};
    color: ${props => props.active ? props.theme.primaryColor : "black"};
`

const StyledP = styled.p`
    text-align: left !important;
    color: black;
    font-weight: 700;
    margin-bottom: 1.2rem;
`

export const Technology = observer((props) => {
    return(
        <div>
            {!props.displayTitle && <StyledP>&nbsp;</StyledP>}
            <StyledA onClick={() => {props.onClick(props.technology)}}>
                {props.displayTitle && <StyledP bold={true}>Select a technology</StyledP>}
                <Section active={props.active}>
                    <TechnologyImage src={BASE_URL + props.technology.image_url} />
                </Section>
                <Label active={props.active}>{props.technology.name}</Label>
            </StyledA>
        </div>
    )
})