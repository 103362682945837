import styled from "styled-components";
import {Button} from "react-bootstrap";

export const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.primaryColor};
  border-color: ${props => props.theme.primaryColor};
  padding: 0.16rem 5.75rem;
  &:disabled {
    background-color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};
  }
  
  &:hover {
    background-color:  ${props => props.theme.primaryColorHover};
    border-color: ${props => props.theme.primaryColorHover};
  }
  
  &:active {
    background-color:  ${props => props.theme.primaryColorHover};
    border-color: ${props => props.theme.primaryColorHover};
    box-shadow: 0 0 0 0.2rem ${props => props.theme.primayColorHoverBoxShadow};
  }
  
  &:not(:disabled):not(.disabled):active {
    background-color:  ${props => props.theme.primaryColorHover};
    border-color: ${props => props.theme.primaryColorHover};
    box-shadow: 0 0 0 0.2rem ${props => props.theme.primayColorHoverBoxShadow};
  }
  
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.primayColorHoverBoxShadow};
  }
  
  &:focus {
    background-color:  ${props => props.theme.primaryColorHover};
    border-color: ${props => props.theme.primaryColorHover};
    box-shadow: 0 0 0 0.2rem ${props => props.theme.primayColorHoverBoxShadow};
  }
`
