import {observer} from "mobx-react-lite";
import {Technology} from "./Technology";
import {Col} from "react-bootstrap";

export const ListTechnologies = observer((props) => {
    return(
        <>
            {props.technologies && props.technologies.map((technology, idx) => {
                return <Col xxl={1} xl={3} lg={3} md={4} key={idx}>
                    <Technology
                        technology={technology}
                        onClick={props.handleTechnologySelection}
                        active={props.selectedTechnology === technology}
                        displayTitle={idx === 0}
                    />
                </Col>
            })}
        </>
    )
})