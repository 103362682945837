import {Route, Redirect} from "react-router-dom";
import {useContext} from "react";
import {StoreContext} from "./index";
import {observer} from "mobx-react-lite";

export const PrivateRoute = observer(({ children, ...rest }) => {

    const storeContext = useContext(StoreContext)

    return(
        <Route
            {...rest}
            render={({ location }) =>
                storeContext.ReportStore.selectedTechnology !== undefined ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
})