import {Col, Form, Row} from "react-bootstrap";
import {FormControl} from "../../components/FormControl";
import {P} from "../../components/P";
import {PrimaryButton} from "../../components/PrimaryButton";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {isEmailValid} from "../../utils/FormUtils";
import {register} from '../../api/repositories/AuthRepository'
import {toast} from "react-toastify";

export const Register = () => {
    const history = useHistory();

    const [email, setEmail] = useState("")
    const [emailValid, setEmailValid] = useState(undefined)

    const [password, setPassword] = useState("")
    const [passwordValid, setPasswordValid] = useState(undefined)

    const [passwordVerification, setPasswordVerification] = useState("")
    const [passwordVerificationValid, setPasswordVerificationValid] = useState(undefined)

    const [tosAgreed, setTosAgreed] = useState(false)
    const [tosAgreedValid, setTosAgreedValid] = useState(undefined)

    const emailChanged = (e) => {
        const val = e.target.value
        for (let i = 0; i < val.length; i++) {
            let char = val.charAt(i)
            if (char === ' ') {
                return
            }
        }
        setEmail(val)
    }

    const passwordChanged = (e) => {
        const val = e.target.value
        setPassword(val)
    }

    const passwordVerificationChanged = (e) => {
        const val = e.target.value
        setPasswordVerification(val)
    }

    const tosAgreedChanged = (e) => {
        const val = e.target.checked
        setTosAgreed(val)
    }

    const validateEmail = () => {
        if(isEmailValid(email)) {
            setEmailValid(undefined)
            return true
        } else {
            setEmailValid("Please enter a valid email address.")
            return false
        }
    }

    const validatePassword = () => {
        if (password.length <= 0) {
            setPasswordValid("Please enter a password")
            return false
        } else {
            setPasswordValid(undefined)
            return true
        }
    }

    const validatePasswordConfirmation = () => {

        if (password !== passwordVerification) {
            setPasswordVerificationValid("Password's do not match")
            return false
        } else {
            setPasswordVerificationValid(undefined)
            return true
        }
    }

    const validateTos = () => {
        if (tosAgreed) {
            setTosAgreedValid(undefined)
            return true
        } else {
            setTosAgreedValid("You must accept our terms of service to register.")
            return false
        }
    }

    const registerBtnClicked = () => {
        if (!validateEmail() || !validatePassword()
            || !validatePasswordConfirmation() || !validateTos()) {
            return
        }

        register(email, password, passwordVerification).then((resp) => {
            history.push('/completed/Please check your email for a verification link.')
        }).catch((err) => {
            if(err.response.data) {
                toast.error(err.response.data.errors.full_messages[0])
            } else {
                toast.error("There was an error registering. Please try again.")
            }
        })
    }

    return (
        <Row className="justify-content-center align-items-center" style={{height: "90vh"}}>
            <Col xl={4} lg={6} md={8} sm={8}>
                <P bold={true}>Register for a NanoFC account</P>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <FormControl type="email" onChange={emailChanged} value={email} isInvalid={emailValid !== undefined}/>
                        <Form.Control.Feedback type="invalid">
                            {emailValid}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <FormControl type="password" onChange={passwordChanged} value={password} isInvalid={passwordValid !== undefined}/>
                        <Form.Control.Feedback type="invalid">
                            {passwordValid}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPasswordVerification">
                        <Form.Label>Re-enter password</Form.Label>
                        <FormControl type="password" onChange={passwordVerificationChanged} value={passwordVerification} isInvalid={passwordVerificationValid !== undefined}/>
                        <Form.Control.Feedback type="invalid">
                            {passwordVerificationValid}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPasswordVerification">
                        <Form.Check
                            type="checkbox"
                            id='agree-to-tos'
                            label='I agree to the Terms of Service'
                            checked={tosAgreed}
                            onChange={tosAgreedChanged}
                            isInvalid={tosAgreedValid !== undefined}
                        />
                        <Form.Control.Feedback type="invalid">
                            {tosAgreedValid}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <PrimaryButton onClick={registerBtnClicked}>
                        Register
                    </PrimaryButton>
                </Form>
            </Col>
        </Row>
    )
}