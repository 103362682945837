import {Col, Row} from "react-bootstrap";

export const TermsAndConditions = () => {
    return <>
        <Row className="pt-3">
            <Col lg={{span: 6, offset: 3}}>
                <h1 className="text-center">TERMS AND CONDITIONS</h1>
                <p>Last updated: June, 2021</p>

                <p className="font-weight-bold">
                    By accessing or using the website operated at <a href="https://app.nanofulfillment.com" target="_blank">app.nanofulfillment.com</a> together with the associated mobile application, software, and such other applications and website locations as made available from time to time (collectively, the “Website”) and the services offered through the Website, you (“you” and, together with all persons accessing or using the Website, collectively, the “Users”) signify that you have read, understand and agree to be bound by these Terms and Conditions (the “Terms and Conditions”) with Nanofulfillment Inc. and its officers, directors, partners, associates, employers, affiliates, subsidiaries, parents, companies, nominees, representatives, employees, successors, clients, agents, and assigns (“us”, “we” or “our”), in all respects with respect to the Website.
                </p>

                <p className="font-weight-bold">
                    PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE, BUT ARE NOT LIMITED TO, VARIOUS LIMITATIONS AND EXCLUSIONS, AND INDEMNITIES.
                </p>

                <p>
                    Your use of the Website is subject to these Terms and Conditions. <span className="font-weight-bold">If you are not willing to be bound by each and every term or condition, or if any representation made herein by you is not true, you may not use, and must cease using, the Website.</span>
                </p>

                <h3>INTRODUCTION</h3>

                <p className="font-weight-bold">We have conceptualized and developed the Website integrating proprietary software in relation to warehouse automating and reporting which yields calculated outputs. You understand and accept that the underlying software is provided “as is” and any expressed or implied warranties of merchantability and fitness for a particular purpose are disclaimed. In no event shall we be liable of any direct, indirect, incidental, special, exemplary, or consequential damages (including, but not limited to, procurement of substitute goods or services; loss of use, data, or profits; or business interruption). As the Website, in its entirety or parts thereof, may, from time to time, be of experimental nature and deployed for testing purposes only, you acknowledge that this version of the Website, even though developed in accordance to state of the art, is likely to contain bugs, defects, or errors (including any bug, defect, or error relating to or resulting from the display, manipulation, processing, storage, transmission, or use of data) that materially and adversely affects the use, functionality, or performance of the Website or any product or system containing or used in conjunction with us. </p>

                <p className="font-weight-bold">THERE IS NO WARRANTY FOR THE WEBSITE, TO THE EXTENT PERMITTED BY APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING WE PROVIDE THE SOFTWARE “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THE WEBSITE AND RELATED SOFTWARE’S CALCULATION OUTPUT AND YOU FURTHER UNDERTAKE SOLE RESPONSIBILITY AND ALL OBLIGATIONS ARISING OUT OF AND IN RELATION TO VERIFYING AND AUTHENTICATING THE WEBSITE AND RELATED SOFTWARE’S CALCULATION.  THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE WEBSITE AND RELATED SOFTWARE IS WITH YOU. </p>

                <ol>
                    <li><span className="font-weight-bold">Privacy.</span> You acknowledge that you have read the Privacy Statement located on the Website at <a href="https://www.nanofulfillment.com/privacypolicy" target="_blank">www.nanofulfillment.com/privacypolicy</a>, as it may be updated from time to time (the "<span className="font-weight-bold">Privacy Statement</span>"), and hereby consent to the collection, use, disclosure and retention by us of your personal information (whether previously collected or to be collected) for the purposes identified therein. You also consent to our use of such personal information in accordance with applicable terms and conditions contained in the Privacy Statement, which is incorporated herein by reference and forms an integral part of these Terms and Conditions.</li>
                    <li><span className="font-weight-bold">Amendments to these Terms and Conditions.</span> We reserve the right to amend these Terms and Conditions at any time without notice to you, but we will use reasonable efforts to publish each amendment before such amendment becomes effective. We will ensure that the latest, fully amended version of these Terms and Conditions is published on the Website. <span className="font-weight-bold">You are responsible for regularly reviewing the Website to obtain timely notice of such amendments</span>. If any amendment is unacceptable to you, you may terminate the agreement between you and us regarding the use of the Website as set out in these Terms and Conditions. If you continue to use the Website after the effective date of each amendment, you will be conclusively deemed to have accepted such amended version of these Terms and Conditions.</li>
                    <li><span className="font-weight-bold">Legal Capacity.</span> You represent and warrant that you possess the legal right, capacity and ability to agree to these Terms and Conditions and use the Website in accordance with them. If you are using the Website on behalf of a corporation or other organization, you represent and warrant that you have the ability to agree to these Terms and Conditions on behalf of such organization and all references to "<span className="font-weight-bold">you</span>" throughout these Terms and Conditions will include such organization, jointly and severally with you personally.</li>
                    <li><span className="font-weight-bold">License.</span> We grant to you a non-exclusive, non-transferable, limited license only to use the Website, in accordance with the provisions set out in these Terms and Conditions. All rights not expressly granted to you in these Terms and Conditions are reserved by us and, if applicable, our licensors.</li>
                    <li><span className="font-weight-bold">Your Account and Account Use.</span> If your use of the Website requires an account identifying you as a user of the Website (an "<span className="font-weight-bold">Account</span>"), then,</li>
                    <ol type="a">
                        <li><span className="font-weight-bold">Responsibility</span>—you are solely responsible for</li>
                            <ol type="i">
                                <li>your Account and the maintenance, confidentiality and security of your Account and all passwords related to your Account, and</li>
                                <li>any and all activities that occur under your Account, including all activities of any persons who gain access to your Account with or without your permission</li>
                            </ol>
                        <li><span className="font-weight-bold">Notification</span>—you agree to immediately notify us, to the extent that you are or become aware, of</li>
                            <ol type="i">
                                <li>any unauthorized use of your Account, any service provided through your Account or any password related to your Account, or</li>
                                <li>any other breach of security with respect to your Account or any service provided through it, and you agree to provide assistance to us, as requested, to stop or remedy any breach of security related to your Account, and</li>
                            </ol>
                        <li><span className="font-weight-bold">Accuracy</span>—you agree to provide true, current, accurate and complete customer information as requested by us from time to time and you agree to promptly notify us of any changes to this information as required to keep such information held by us current, complete and accurate.</li>
                    </ol>
                    <li><span className="font-weight-bold">Website Limitations</span>. The Website depends on the Internet, including networks, cabling, facilities and equipment that is not in our control; accordingly (i) any representation made by us regarding access performance, speeds, reliability, availability, use or consistency of the Website is on a "commercially reasonable efforts" basis, (ii) we cannot guarantee any minimum level regarding such performance, speed, reliability, availability, use or consistency, and (iii) data, messages, information or materials sent over the Internet may not be completely private, and your anonymity is not guaranteed.</li>
                    <li><span className="font-weight-bold">Acceptable Use and Prohibitions.</span></li>
                    <ol type="a">
                        <li><span className="font-weight-bold">Lawful Use</span>. You will ensure that</li>
                        <ol type="i">
                            <li>you only use the Website for lawful purposes, and</li>
                            <li>if at any time you become aware of any violation, by any person or entity, of any part of these Terms and Conditions, you will immediately notify us and provide us with assistance, as requested, to stop or remedy such violation.</li>
                        </ol>
                        <li><span className="font-weight-bold">Prohibited Conduct</span>. Without limiting the generality of any other restriction in these Terms and Conditions, you agree that you will not, in connection with the Website, directly or directly do or permit any of the following:</li>
                        <ol type="i">
                            <li>post, upload, reproduce, distribute or otherwise transmit any Content (defined below) that</li>
                            <ol type="a">
                                <li>is unauthorized or unsolicited commercial communications, junk or bulk communications or other "spam" (whether or not using e-mail services, including instant messaging, blog or comment spam) or is otherwise duplicative or unsolicited,</li>
                                <li>contains a virus or other harmful, disruptive or surreptitious component,</li>
                                <li>is defamatory, infringing, or unlawful,</li>
                                <li>is inappropriate, profane, obscene, indecent, or contains information without suitable or lawfully-required access controls (which controls will in no event be our responsibility),</li>
                                <li>gives rise to civil liability, or otherwise violates the rights or assists others to violate the rights of us or any third party, such violations including engaging in copyright infringement, invasion of privacy, trademark infringement or defamation, or</li>
                                <li>constitutes a criminal offence, or otherwise engages in or assists others to engage in any criminal offence or unauthorized use of a computer, mischief in relation to data or fraud;</li>
                                <li>engage in activities or any conduct that violates the legal rights of others, including rights relating to privacy and publicity;</li>
                                <li>scan or probe another computer system, obstruct or bypass computer identification procedures or engage in unauthorized computer or network trespass without the express permission of the owners of such computer systems;</li>
                                <li>forge headers or otherwise manipulate any protocols or identifiers used in any system or protocol in such a manner to disguise the origin of any data transmitted using the Website;</li>
                                <li>impersonate or falsely represent your association with any person, including a representative of us;</li>
                                <ol type="i">
                                    <li>disrupt or threaten the integrity, operation or security of any Website, any computer or any Internet system;</li>
                                    <li>disable or circumvent any access control or related process or procedure established with respect to the Website;</li>
                                    <li>sublicense, share, resell, reproduce, copy, distribute, redistribute, or exploit for any commercial purposes (except for your internal business purposes), any portion of, use of or access to, any Website, except where expressly authorized by us; or</li>
                                    <li>extract, gather, collect, or store personal information about others without their express consent.</li>
                                </ol>
                            </ol>
                        </ol>
                        <li><span className="font-weight-bold">Our Remedies</span>. Without limiting any of our rights, we may suspend, restrict or terminate your use of the Website without notice if, in our sole and absolute discretion, we determine or believe that you have violated any of the acceptable use rules set out above.</li>
                    </ol>
                    <li><span className="font-weight-bold">Proprietary Rights.</span></li>
                    <ol type="a">
                        <li><span className="font-weight-bold">Content</span>—"<span className="font-weight-bold">Content</span>" means all materials and content, including designs, editorial, text, graphics, audiovisual materials, multimedia elements, photographs, videos, music, sound recordings, reports, documents, software, information, formulae, patterns, data and any other work.</li>
                        <li><span className="font-weight-bold">Third-Party Content</span>—Content accessed or available through the Website or the Internet may be owned by parties other than you or us (collectively, "Third Party Content") and may be protected by applicable copyrights, trademarks, patents, trade secrets or other proprietary rights and laws. Nothing in your use of the Website or these Terms and Conditions grants you any right, title or interest in or to this Third Party Content except for the limited right to use the Website as set out in these Terms and Conditions. The Website may contain links to Third Party Content on websites operated by parties other than you or us ("Third Party Websites"). We are under no obligation to verify the accuracy of the contents on Third Party Websites, and we do not endorse, warrant, promote or recommend any services or products that may be offered by or accessed through such Third Party Websites or the operators of them.</li>
                        <li><span className="font-weight-bold">Our Content</span>—Except where expressly stated otherwise, all right, title and interest in and to the Website and all Content, reports, source code, processes, designs, technologies, URLs, domain names, marks and logos forming any part of the Website (collectively, "<span className="font-weight-bold">Our Content</span>") is fully vested in us, our licensors or our suppliers and are protected by applicable copyrights, trade-marks, patents, trade secrets or other proprietary rights and laws. You agree that Our Content is licensed subject to the terms of these Terms and Conditions, including the disclaimers and limitations of liability herein. Nothing in your use of the Website or these Terms and Conditions grants you any right, title or interest in or to Our Content except the limited right to use the Website as set out in these Terms and Conditions. Unless otherwise expressly authorized by us in writing, you agree not to</li>
                        <ol type="i">
                            <li>copy, modify, deep link, rent, lease, loan, sell, assign, sublicense, grant a security interest in or otherwise transfer any right or interest in Our Content</li>
                            <li>distribute, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover the source code, of Our Content, to the maximum extent that such prohibition is permitted by applicable law</li>
                            <li>remove any proprietary notices or labels on or in Our Content, or</li>
                            <li>allow any other person or entity to engage in any of the foregoing.</li>
                        </ol>
                        <li><span className="font-weight-bold">Your Content</span>—We do not claim ownership of any Content that you post, upload, input, provide, submit or otherwise transmit to us, or any third party, using the Website (collectively, "<span className="font-weight-bold">Your Content</span>"); however, you agree that by posting, uploading, inputting, providing, submitting, entering or otherwise transmitting your Content to us or any third party using the Website,</li>
                        <ol type="i">
                            <li><span className="font-weight-bold">License to Us</span>—You have thereby granted us a royalty-free, non-exclusive, worldwide, fully paid-up limited license to use, copy, distribute, transmit, display, edit, delete, publish and translate Your Content to the extent reasonably required by us to provide the Website to our customers or to ensure adherence to or enforce the terms of these Terms and Conditions,</li>
                            <li><span className="font-weight-bold">Your Warranty to Us</span>—You will have thereby confirmed, represented and warranted to us that you have all rights, titles and interests, as well as the power and authority necessary, to grant the license to Your Content set out above, and</li>
                            <li><span className="font-weight-bold">Your Indemnity of Us</span>—You will indemnify and save us harmless from and against any liabilities, actions, proceedings, claims, causes of action, demands, debts, losses, damages, charges and costs, including reasonable legal costs, any amount paid to settle any action or to satisfy a judgment and expenses of any kind and character whatsoever incurred by us relating to or arising from Your Content, including instances where the Your Content (A) infringes any Third Party Content or other third-party intellectual property rights, or (B) is inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful.</li>
                        </ol>
                        <li><span className="font-weight-bold">Advertising</span>—We will have the right, without notice, to insert advertising data into the Website, so long as this does not involve our transmission of any of your personal information in contravention of our Privacy Statement.</li>
                        <li><span className="font-weight-bold">Public Transmission and Caching</span>—You acknowledge and agree that our management and delivery of the Website to you may involve transmission and storage of Your Content and other Content to select service providers, including cloud service providers, which may be located outside of Canada, and therefore may be subject to the laws and lawful disclosure requirements of the jurisdictions(s) where Your Content and other Content is transmitted or stored. You further acknowledge and agree that Your Content may be subject to "caching" or other technical processing or transmission policies and procedures by us or at intermediate locations on the Internet.</li>
                        <li><span className="font-weight-bold">Deletion of Your Content</span>—If you delete the account to which Your Content is connected, or request deletion of your account to which Your Content is connected, you acknowledge and agree that we may retain a copy or copies of same for archival or compliance purposes or to otherwise provide the Website to you or others, in accordance with our retention policy as referenced in our Privacy Statement, and subject always to your license to us set out above.</li>
                        <li><span className="font-weight-bold">Disclosure to Third Parties</span>— You agree that we may, without notice or liability, disclose to third parties, including, but not limited to, third party service providers, servers, and/or potential acquirers, any of your information or Your Content; monitor use of the Website and monitor, review and retain Your Content if we believe in good faith that such activity is reasonably necessary to provide the Website to customers, ensure adherence to or enforce the terms of these Terms and Conditions; comply with any laws or regulations; respond to any allegation of illegal conduct or claimed violation of third party rights; or protect us or others.</li>
                        <li><span className="font-weight-bold">Compliance and Complaints</span>— We do not have any obligation to censor or review any of Your Content, to censor or review any Third-Party Content, or to monitor use of the Website. However, if we receive a complaint relating to use of the Website by you, you acknowledge and agree that we may, in our sole and absolute discretion and without notice or liability, investigate the complaint, restrict, suspend or terminate any service involved, or remove Your Content from our servers.</li>
                        <li><span className="font-weight-bold">Termination of Accounts</span>— We may, in our sole discretion, suspend, restrict or terminate your Account and your use of the Website, effective at any time, without notice to you, for any reason, including because the operation or efficiency of the Website or our or any third party's equipment or network is impaired by your use of the Website, any amount is past due from you to us, we have received a third party complaint which relates to your use or misuse of the Website, or you have been or are in breach of any term or condition of these Terms and Conditions. We will have no responsibility to notify any third party, including any third party providers of services, merchandise or information, of any suspension, restriction or termination of your access to the Website.</li>
                        <li><span className="font-weight-bold">DISCLAIMERS, LIMITS OF LIABILITY AND INDEMNITIES.</span></li>
                        <ol type="i">
                            <li><span className="font-weight-bold">CUSTOMER ACKNOWLEDGEMENT—YOU ACKNOWLEDGE AND AGREE THAT:</span></li>
                            <ol type="a">
                                <li>ALL USE OF THE WEBSITE PROVIDED BY US IS AT YOUR OWN RISK;</li>
                                <li>THE WEBSITE MAY CONTAIN LINKS TO OTHER WEBSITES, WHICH ARE PROVIDED SOLELY AS A CONVENIENCE TO YOU AND THE INCLUSION OF ANY SUCH LINK DOES NOT IMPLY ENDORSEMENT, INVESTIGATION OR VERIFICATION BY US OF SUCH WEBSITES OR THE INFORMATION CONTAINED THEREIN; AND</li>
                                <li>THE WEBSITE PROVIDED UNDER THESE TERMS AND CONDITIONS IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.</li>
                            </ol>
                            <li><span className="font-weight-bold">DISCLAIMER OF WARRANTIES</span>—AS STATED ABOVE, WE MAKE NO CONDITIONS, WARRANTIES OR REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, USABILITY, SECURITY, QUALITY, CAPACITY, PERFORMANCE, AVAILABILITY, TIMELINESS OR ACCURACY OF THE WEBSITE OR ANY OTHER PRODUCTS SUPPLIED UNDER THESE TERMS AND CONDITIONS. WE EXPRESSLY DISCLAIM ALL CONDITIONS, WARRANTIES AND REPRESENTATIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING IMPLIED CONDITIONS OR WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, DURABILITY, TITLE AND NON-INFRINGEMENT, WHETHER ARISING BY USAGE OF TRADE, BY COURSE OF DEALING, BY COURSE OF PERFORMANCE, AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE HOWSOEVER.</li>
                            <li><span className="font-weight-bold">NO LIABILITY</span>—NOTWITHSTANDING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, IN NO EVENT WILL WE, OUR AFFILIATES, OR OUR CONTROLLING PARTIES, AGENTS, EMPLOYEES, SUPPLIERS, RESELLERS AND DISTRIBUTORS (COLLECTIVELY, IN THESE CAPITALIZED SECTIONS, "<span className="font-weight-bold">WE</span>" OR "<span className="font-weight-bold">US</span>") BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OR LOSSES WHATSOEVER, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OPPORTUNITY, EARNINGS, USE OR DATA, ARISING DIRECTLY OR INDIRECTLY FROM OR RELATED TO THESE TERMS AND CONDITIONS OR THE USE, OR NON-USE OF THE WEBSITE OR ANY SOFTWARE, REGARDLESS OF THE CAUSE OF ACTION AND EVEN IF ONE OR MORE OF US HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES, INCLUDING DAMAGES OR LOSSES ARISING FROM OR IN ANY WAY RELATED TO THE FOLLOWING: (i) ANY LOSS OR DAMAGE TO DATA ARISING DIRECTLY OR INDIRECTLY OUT OF USE OR NON-USE OF THE WEBSITE OR RELATED COMPONENT; (ii) DELAYS, ERRORS, INTERRUPTIONS, MISTAKES, OMISSIONS, NON-DELIVERY, INCORRECT DELIVERY, OR DEFECTS ON OR RELATED TO THE WEBSITE. WITHOUT RESTRICTING THE FOREGOING, IF CIRCUMSTANCES ARISE IN WHICH YOU OR ANOTHER PARTY IS ENTITLED TO RECOVER DAMAGES FROM ONE OR MORE OF US, THE AGGREGATE LIABILITY OF US FOR DAMAGES IS LIMITED TO THE AMOUNT PAID TO US BY YOU THROUGH THE WEBSITE DURING THE SIX-MONTH PERIOD PRECEDING THE CIRCUMSTANCES IN WHICH SUCH LIABILITY ARISES.</li>
                            <li><span className="font-weight-bold">APPLICABILITY</span>—SOME JURISDICTIONS PROHIBIT THE DISCLAIMER OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OF CERTAIN TYPES OF LIABILITY. IN SUCH CIRCUMSTANCES, TO THE EXTENT THAT SUCH PROHIBITIONS PROHIBIT ANY EXCLUSIONS AND LIMITATIONS IN THESE TERMS AND CONDITIONS, SUCH EXCLUSIONS AND LIMITATIONS WILL NOT APPLY TO YOU STRICTLY TO THE EXTENT NECESSARY TO MAKE THESE TERMS AND CONDITIONS CONSISTENT WITH SUCH PROHIBITIONS.</li>
                        </ol>
                    </ol>
                </ol>
                <h3>General Terms</h3>
                <ol start="9">
                    <li><span className="font-weight-bold">Enforceability</span>. Your use of the Website and the content and features accessed through the Website constitute your electronic signature to the agreement set out in these Terms and Conditions and your consent to enter into agreements with us electronically. This Agreement will be deemed to satisfy any writings requirements of any applicable law, notwithstanding that the agreement set out in these Terms and Conditions is written and accepted electronically. All contracts between you and us completed electronically will be deemed for all legal purposes to be in writing and legally enforceable as a signed written agreement.</li>
                    <li><span className="font-weight-bold">Interpretation</span>. In these Terms and Conditions, (i) the captions and headings are for convenience only and do not constitute substantive matter and are not to be construed as interpreting the contents of these Terms and Conditions, (ii) the word "<span className="font-weight-bold">including</span>", the word "<span className="font-weight-bold">includes</span>" and the phrase "<span className="font-weight-bold">such as</span>", when following a general statement or term (whether or not non-limiting language such as "without limitation" or "but not limited to" or other words of similar import are used with reference thereto), is not to be construed as limiting, and the word "or" between two or more listed matters does not imply an exclusive relationship between the matters being connected, and (iii) all references to website addresses or URLs will also include any successor or replacement websites containing substantially similar information as the referenced website(s).</li>
                    <li><span className="font-weight-bold">Waiver of Rights and Remedies</span>. Our failure to insist upon or enforce strict performance of any provision of these Terms and Conditions will not be construed as a waiver of any provision or right. Neither the course of conduct between you and us nor trade practice will act to modify any provision of these Terms and Conditions. Our rights, powers and remedies in these Terms and Conditions, including without limitation the right to suspend, restrict or terminate any use of the Website, are cumulative and in addition to and not in substitution for any right, power or remedy that may be available to us at law or in equity.</li>
                    <li><span className="font-weight-bold">Severability</span>. If any provision of these Terms and Conditions is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability will attach only to such provision or part thereof and the remaining part of such provision and all other provisions hereof will continue in full force and effect.</li>
                    <li><span className="font-weight-bold">Limitation Period</span>. Any cause of action you may have with respect to these Terms and Conditions or the Website must be commenced within one year after the claim or cause of action arose, or it will be barred.</li>
                    <li><span className="font-weight-bold">Notices</span>. Any notice, consent, waiver, approval, authorization or other communication to be delivered in connection with these Terms and Conditions</li>
                    <ol type="a">
                        <li>by us to you will be deemed to have been effectively and validly given if delivered or sent to any of the contact particulars then listed in your Account;</li>
                        <li>by you to us will only be deemed to have been effectively and validly given if in writing and delivered or submitted to the Chief Privacy Officer.</li>
                    </ol>
                    <li><span className="font-weight-bold">Notices Assignment and Inurement</span>. We may at any time assign our rights and obligations under these Terms and Conditions, in whole or in part, without notice to you. You may not assign these Terms and Conditions without our prior, written consent. These Terms and Conditions will inure to the benefit of and bind you and us and our respective personal and legal representatives, successors and permitted assigns.</li>
                    <li><span className="font-weight-bold">Relationship</span>. You agree that no joint venture, partnership, fiduciary, employment or agency relationship exists between us and you as a result of these Terms and Conditions or use of the Website.</li>
                    <li><span className="font-weight-bold">Force Majeure</span>. Neither party will be responsible for a failure to fulfil its obligations under these Terms and Conditions or for delay in doing so if such failure or delay is due to circumstances beyond its reasonable control, such as acts of nature, acts of government, pandemics, war, riots, strikes and accidents.</li>
                    <li><span className="font-weight-bold">Jurisdiction and Governing Law</span>.  Any dispute between you and us shall be submitted to the exclusive jurisdiction of the competent Courts of the Province of Ontario, with the exclusion of any other jurisdiction or arbitration. These Terms and Conditions shall be governed by and construed and interpreted in accordance with the substantive laws of Ontario excluding the conflict of law rules. </li>
                    <li><span className="font-weight-bold">English Language</span>. The parties have requested and agree that these Terms and Conditions and all documents relating there to be drawn up in English.</li>
                    <li><span className="font-weight-bold">Entire Agreement</span>. These Terms and Conditions, as amended from time to time, including any and all documents, websites, rules, terms and policies referenced herein, including but not limited to the Privacy Statement, constitutes the entire agreement between us and you with respect to the matters referred to in these Terms and Conditions and supersedes all prior and contemporaneous agreements and understandings, whether electronic, oral or written, between us and you with respect to such matters.</li>
                </ol>
            </Col>
        </Row>
    </>
}